const environmentName = process.env.REACT_APP_ENVIRONMENT;
const recaptchaSiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
let apiUrl;
let wsUrl;

if (environmentName==='PROD'){
  apiUrl="https://api.ilt.middle.education/api/v1"
  wsUrl="https://api.ilt.middle.education"
}
else if (environmentName==='DEV'){
  apiUrl="https://api.iltdev.middle.education/api/v1"
  wsUrl="https://api.iltdev.middle.education"
}
else{
  apiUrl="http://localhost/api/v1"
  wsUrl="http://localhost"
}

export const environment = {
  iltBaseApiUrl: apiUrl,
  wsBaseUrl: wsUrl,
  recaptchaSiteKey:recaptchaSiteKey,
};
